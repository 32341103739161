
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "../../misc/ErrorMessageHandler.mixins";
import {namespace} from "vuex-class";
import {validationMixin} from "vuelidate";
import {email, required} from "vuelidate/lib/validators";
import Company from "@/models/Company";
import {APPLICATION_STORE_NAME, ApplicationStoreGetters} from "@/store/application.store";
import Employee from "@/models/Employee";
import EmployeeRepository from "@/api/repositories/EmployeeRepository";
import {maxLength} from "@/misc/CustomValidators";
import {MaxLengthValidation} from "@/enum/MaxLengthValidation.enum";

const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  mixins: [validationMixin],
  validations: {
    employeeCopy: {
      firstName: {required, maxLength: maxLength(MaxLengthValidation.FIRST_NAME)},
      lastName: {required, maxLength: maxLength(MaxLengthValidation.LAST_NAME)},
      account: {
        email: {required, email, maxLength: maxLength(MaxLengthValidation.EMAIL)},
      }
    }
  }
})

export default class EditEmployeeComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({ default: () => false })
  public isEditing!: boolean;

  @Prop()
  private employee?: Employee;

  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_COMPANY)
  private currentCompany!: Company;

  /**
   * flag that indicates if the view is loading
   * @private
   */
  private isLoading: boolean = false;

  /**
   * copy of the passed employee that can be mutated without editing the original
   */
  public employeeCopy?: Employee = new Employee();

  /**
   * uses the current stored company to create a mutatable copy
   * @private
   */
  private created() {
    this.employeeCopy = Employee.parseFromObject(this.employee);
  }

  /**
   * updates the information of the company
   * @private
   */
  private async updateEmployeeInformation() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.EDIT_COMPANY_MISSING_DATA');
      return;
    }

    try {
      if(this.isEditing) {
        // Init body and compare which attributes changed
        const body = new Map<string, any>();

        body.set("companyId", this.employeeCopy?.id);
        this.employeeCopy?.account.email === this.employee?.account.email && body.set("email", this.employeeCopy?.account.email);
        this.employeeCopy?.firstName === this.employee?.firstName && body.set("firstName", this.employeeCopy?.firstName);
        this.employeeCopy?.lastName === this.employee?.lastName && body.set("lastName", this.employeeCopy?.lastName);

        await EmployeeRepository.editEmployee(this.employeeCopy!.id, this.employeeCopy!);

        this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.CHANGE_SUCCESS');

        if(this.employeeCopy?.account.email !== this.employee?.account.email) {
          this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.EMPLOYEE_EMAIL_CHANGED');
        }
      } else {
        await EmployeeRepository.createEmployee(this.currentCompany.id, this.employeeCopy!);
        this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.CREATED_EMPLOYEE');

      }

      this.dismiss(true);
    } catch(e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 409:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.EMPLOYEE_EMAIL_ALREADY_EXISTS');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        }
      });
    }
  }

  /**
   * closes the modal
   */
  private dismiss(deleted: boolean = false) {
    this.employeeCopy = new Employee();
    this.$emit('closeDialog', deleted);
  }

}

